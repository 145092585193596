import { defineStore } from "pinia";
import { createCookie, getCookie, loadAnyScript, setModalVisibility, getSymbolFromCurrencyCode } from "../helpers/helpers";

export const useCallsStore = defineStore("calls", {
    state: () => ({
        locationObject: null,
        gameUri: null,
        currentGame: null,
        userObject: null,
        realBalanceObject: null,
        bonusBalanceObject: null,
        virtualBalanceObject: null,
        paymentConnectionsArray: [],
        gameSessionConnectionArray: [],
        gameRoundsConnectionArray: [],
        limitsArray: [],
        documentsArray: [],
        bonusesArray: [],
        depositBonusesArray: [],
        depositPaymentOptions: [],
        withdrawPaymentOptions: [],
        paymentInfoList: [],
        userCampaignsList: [],
        vipProgressObject: null,
        liveGameStatsArray: [],
        recentWinningsArray: [],
        filteredRecentWinningsArray: [],
        filteredGameProvidersArray: [],
        filterGameProvidersCommand: null,
    }),
    actions: {
        // NON PERSISTED CALLS
        setLocationObject(newValue) {
            this.locationObject = Object.assign({}, newValue);
        },
        setGameUri(newValue, game, router, myStore) {
            if (game) {
                createCookie("gameId", game.gameId, 180);
                this.currentGame = Object.assign({}, game);
                router.push("/game");
                setTimeout(function () {
                    // When a game is launched from the /game route the watch on the route in the home.vue will not be triggered (Because we are already in /game)
                    // So I need to set the modal visibility manually
                    setModalVisibility(true);
                    myStore.setDisabledGameThumbnails(false);
                }, 100);
            } else {
                this.currentGame = null;
                setModalVisibility(false, router);
            }
            this.gameUri = newValue;
        },
        initSportsBook(properties, configStore, router, myStore) {
            loadAnyScript(properties.scriptUri, "altenar", (scriptStatus) => {
                if (scriptStatus !== "alreadyLoaded" && window.altenarWSDK) {
                    window.altenarWSDK.init({
                        integration: configStore.config.originalName.toLowerCase(),
                        culture: `${configStore.config.currentLanguage.code}-${this.countryCode.toUpperCase()}`,
                        numFormat: `${configStore.config.currentLanguage.code}-${this.countryCode.toUpperCase()}`,
                        token: properties.token,
                    });
                } else if (properties.token && window.altenarWSDK) {
                    window.altenarWSDK.set({
                        token: properties.token,
                    });
                }

                setTimeout(() => {
                    createCookie("gameId", "altenarSportsBook", 180);
                    this.gameUri = "altenarSportsBook";
                    router.push("/game");
                    setModalVisibility(true);
                }, 100);

                setTimeout(() => {
                    window.ASB = window.altenarWSDK.addSportsBook({
                        props: {
                            page: "overview",
                        },
                        container: document.getElementById("altenarsportsbook"),
                    });
                    myStore.setDisabledGameThumbnails(false);
                }, 1200);
            });
        },
        setUserObject(newValue) {
            this.userObject = newValue ? Object.assign({}, newValue) : null;
        },
        setBalanceObjects(newValue) {
            if (newValue === null) {
                this.realBalanceObject = null;
                this.bonusBalanceObject = null;
            } else if (this.userObject && this.userObject.currencyCode) {
                newValue.map((balance) => {
                    if (balance.type === "REAL" && balance.currencyCode === this.userObject.currencyCode) {
                        this.realBalanceObject = Object.assign({}, this.realBalanceObject, balance);
                    } else if (balance.type === "BONUS" && balance.currencyCode === this.userObject.currencyCode) {
                        this.bonusBalanceObject = Object.assign({}, this.bonusBalanceObject, balance);
                    } else if (balance.type === "VIRTUAL") {
                        this.virtualBalanceObject = Object.assign({}, this.virtualBalanceObject, balance);
                    }
                });
            } else {
                newValue.map((balance) => {
                    if (balance.type === "REAL") {
                        this.realBalanceObject = Object.assign({}, this.realBalanceObject, balance);
                    } else if (balance.type === "BONUS") {
                        this.bonusBalanceObject = Object.assign({}, this.bonusBalanceObject, balance);
                    } else if (balance.type === "VIRTUAL") {
                        this.virtualBalanceObject = Object.assign({}, this.virtualBalanceObject, balance);
                    }
                });
            }
        },
        setPaymentConnectionsArray(newValue) {
            this.paymentConnectionsArray = [...newValue];
        },
        setGameSessionConnectionArray(newValue) {
            this.gameSessionConnectionArray = [...newValue];
        },
        setGameRoundsConnectionArray(newValue) {
            this.gameRoundsConnectionArray = [...newValue];
        },
        setLimitsArray(newValue) {
            this.limitsArray = [...newValue];
        },
        setDocumentsArray(newValue) {
            this.documentsArray = [...newValue];
        },
        setBonusesArray(newValue) {
            this.bonusesArray = [...newValue];
        },
        setDepositBonusesArray(newValue) {
            this.depositBonusesArray = [...newValue];
        },
        setDepositPaymentOptions(newValue) {
            this.depositPaymentOptions = [...newValue];
        },
        setWithdrawPaymentOptions(newValue) {
            this.withdrawPaymentOptions = [...newValue];
        },
        setPaymentInfoList(newValue) {
            this.paymentInfoList = [...newValue];
        },
        setUserCampaignsList(newValue) {
            this.userCampaignsList = [...newValue];
        },
        setVipProgressObject(newValue) {
            this.vipProgressObject = newValue ? Object.assign({}, newValue) : null;
        },
        setLiveGameStatsArray(newValue) {
            this.liveGameStatsArray = [...newValue];
        },
        setRecentWinningsArray(newValue) {
            this.recentWinningsArray = [...newValue];
        },
        setFilteredRecentWinningsArray(newValue) {
            this.filteredRecentWinningsArray = [...newValue];
        },
        setFilteredGameProvidersArray(newValue) {
            this.filteredGameProvidersArray = [...newValue];
        },
        setFilterGameProvidersCommand(newValue) {
            this.filterGameProvidersCommand = newValue;
        },
        getFullDepositOptions(zimplerEnabled) {
            return fullPaymentOptions(this.depositPaymentOptions, this.paymentInfoList, zimplerEnabled);
        },
        getFullWithdrawOptions(zimplerEnabled) {
            return fullPaymentOptions(this.withdrawPaymentOptions, this.paymentInfoList, zimplerEnabled);
        },
    },
    getters: {
        userCurrencyCode: (state) => {
            return state.realBalanceObject ? state.realBalanceObject.currencyCode : "";
        },
        userCurrency: (state) => {
            return getSymbolFromCurrencyCode(state.userCurrencyCode);
        },
        formattedRealBalance: (state) => {
            return `${state.realBalanceObject ? state.realBalanceObject.amount : "0.00"} ${getSymbolFromCurrencyCode(state.userCurrencyCode)}`;
        },
        formattedBonusBalance: (state) => {
            return `${state.bonusBalanceObject ? state.bonusBalanceObject.amount : "0.00"} ${getSymbolFromCurrencyCode(state.userCurrencyCode)}`;
        },
        formattedVirtualBalance: (state) => {
            return `${state.virtualBalanceObject ? Math.floor(state.virtualBalanceObject.amount) : "0"}`;
        },
        formattedTotalBalance: (state) => {
            const realBalance = state.realBalanceObject ? state.realBalanceObject.amount : "0.00";
            const bonusBalance = state.bonusBalanceObject ? state.bonusBalanceObject.amount : "0.00";
            const totalBalance = parseFloat(realBalance) + parseFloat(bonusBalance);

            if (state.realBalanceObject) {
                return `${totalBalance.toFixed(2)} ${getSymbolFromCurrencyCode(state.userCurrencyCode)}`;
            } else {
                return `${totalBalance.toFixed(2)}`;
            }
        },
        unFormattedRealBalance: (state) => {
            let realBalance = state.realBalanceObject ? state.realBalanceObject.amount : "0.00";
            realBalance = parseFloat(realBalance);
            return realBalance;
        },
        unFormattedTotalBalance: (state) => {
            const realBalance = state.realBalanceObject ? state.realBalanceObject.amount : "0.00";
            const bonusBalance = state.bonusBalanceObject ? state.bonusBalanceObject.amount : "0.00";
            const totalBalance = parseFloat(realBalance) + parseFloat(bonusBalance);
            return totalBalance.toFixed(2);
        },
        countryCode: (state) => {
            if (state.locationObject) {
                return state.locationObject.country.code.toLowerCase();
            }
            return null;
        },
        activeBonuses: (state) => {
            if (state.bonusesArray.length > 0) {
                let activeBonuses = [];
                state.bonusesArray.map((bonus) => {
                    if (bonus.node.unifiedStatus === "ACTIVE" || bonus.node.unifiedStatus === "PENDING") {
                        activeBonuses = [...activeBonuses, bonus.node];
                    }
                });
                return activeBonuses;
            }
            return [];
        },
        availableBonuses: (state) => {
            let availableBonuses = [];
            state.bonusesArray.map((bonus) => {
                if (bonus.node.unifiedStatus === "AVAILABLE") {
                    availableBonuses = [...availableBonuses, bonus.node];
                }
            });

            state.depositBonusesArray.map((depositBonus) => {
                let bonusAlreadyExist = false;
                availableBonuses.map((available) => {
                    if (available.bonusId === depositBonus.bonusId) {
                        bonusAlreadyExist = true;
                    }
                });
                if (bonusAlreadyExist === false) {
                    availableBonuses = [...availableBonuses, depositBonus];
                }
            });
            return availableBonuses;
        },
        filteredDepositBonusesArray: (state) => {
            if (state.depositBonusesArray.length > 1 && getCookie("depositedCustomer") !== "true") {
                // For non deposited costomers we try to only show the welcome offer in the deposit screen bonuses
                let filteredDepositBonuses = state.depositBonusesArray.filter((bonus) => bonus.bonus.name.indexOf("WB") !== -1);
                return filteredDepositBonuses.length > 0 ? filteredDepositBonuses : state.depositBonusesArray;
            }
            return state.depositBonusesArray;
        },
        pendingWithdrawals: (state) => {
            let pendingWithdrawals = [];
            state.paymentConnectionsArray.map((transaction) => {
                if (transaction.node.type === "WITHDRAWAL" && (transaction.node.status === "PENDING" || transaction.node.status === "PENDING_REDIRECT" || transaction.node.status === "PENDING_APPROVAL")) {
                    pendingWithdrawals.push(transaction);
                }
            });
            return pendingWithdrawals;
        },
    },
});

const fullPaymentOptions = (paymentOptions, paymentInfoList, zimplerEnabled) => {
    let options = [];
    let isZimpler = false;

    paymentInfoList.map((info) => {
        paymentOptions.some((payment) => {
            if (isZimpler === false && info.attributes.slug.indexOf(`${payment.method}_${payment.provider}`) !== -1) {
                if (info.attributes.slug.indexOf(`BANK_ZIMPLER`) !== -1 && zimplerEnabled) {
                    // If Zimpler is set in TMA and in STRAPI, we return ZIMPLER only
                    options = [];
                    isZimpler = true;
                }

                const strapiInfo = {
                    name: info.attributes.name,
                    description: info.attributes.description,
                    slug: `${payment.method}_${payment.provider}`,
                };
                options = [...options, Object.assign(strapiInfo, payment)];
                return true;
            }
        });
    });
    return options;
};
