<template>
    <div class="my-container text-center" :class="margin">
        <!-- Location -->
        <router-link v-if="location" :to="location">
            <button class="skin-btn" v-html="getDictionary(dictionary)"></button>
        </router-link>

        <!-- LoggedIn - Promotions and Normal -->
        <div class="btn-group" v-else-if="myStore.loggedIn && promotionsButton">
            <button type="button" class="btn skin-btn second no-border" :class="{ 'btn-shadow-left': btnShadow }" @click="redirect('/promotions')" v-html="getDictionary('PROMOTIONS')"></button>
            <button type="button" class="btn skin-btn" :class="{ 'btn-shadow-right': btnShadow }" @click="redirect('/payments/deposit')" v-html="getDictionary('TOPUP')"></button>
        </div>
        <router-link v-else-if="myStore.loggedIn" to="/payments/deposit"><button class="skin-btn" :class="{ 'btn-shadow-center': btnShadow }" v-html="claim ? getDictionary('CLAIM') : getDictionary('TOPUP')"></button></router-link>

        <!-- Logged Out - Promotions -->
        <div class="btn-group" v-else-if="promotionsButton">
            <button type="button" class="btn skin-btn second" :class="{ 'btn-shadow-left': btnShadow }" @click="redirect('/promotions')" v-html="getDictionary('PROMOTIONS')"></button>
            <button v-if="configStore.config.zimpler && callsStore.countryCode === 'fi'" type="button" class="btn skin-btn" :class="{ 'btn-shadow-right': btnShadow }" @click="redirect('/play')" v-html="getDictionary('DEPOSIT_PLAY')"></button>
            <template v-else>
                <button type="button" class="btn skin-btn no-border" :class="{ 'btn-shadow-center': btnShadow }" @click="redirect('/register')" v-html="getDictionary('REGISTER')"></button>
                <button type="button" class="btn skin-btn second" :class="{ 'btn-shadow-right': btnShadow }" @click="redirect('/login')" v-html="getDictionary('LOGIN')"></button>
            </template>
        </div>

        <!-- Logged Out - Claim -->
        <router-link v-else-if="claim && configStore.config.zimpler && callsStore.countryCode === 'fi'" to="/play"><button class="skin-btn" :class="{ 'btn-shadow-center': btnShadow }" v-html="getDictionary('CLAIM')"></button></router-link>
        <router-link v-else-if="claim" to="/login"><button class="skin-btn" :class="{ 'btn-shadow-center': btnShadow }" v-html="getDictionary('CLAIM')"></button></router-link>

        <!-- Logged Out -->
        <router-link v-else-if="configStore.config.zimpler && callsStore.countryCode === 'fi'" to="/play"><button class="skin-btn" :class="{ 'btn-shadow-center': btnShadow }" v-html="getDictionary('DEPOSIT_PLAY')"></button></router-link>
        <div class="btn-group" v-else>
            <button type="button" class="btn skin-btn no-border" :class="{ 'btn-shadow-left': btnShadow }" @click="redirect('/register')" v-html="getDictionary('REGISTER')"></button>
            <button type="button" class="btn skin-btn second" :class="{ 'btn-shadow-right': btnShadow }" @click="redirect('/login')" v-html="getDictionary('LOGIN')"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "button-component",
    props: {
        location: String,
        dictionary: String,
        btnShadow: Boolean,
        margin: String,
        promotionsButton: Boolean,
        claim: Boolean,
    },
    methods: {
        redirect(path) {
            this.$router.push(path);
        },
    },
};
</script>
