import { createRouter, createWebHistory } from "vue-router";
import { setModalVisibility, setSideMenuVisibility, createCookie } from "../helpers/helpers";
import { useConfigStore } from "../store/useConfigStore";
import { useMyStore } from "../store/useMyStore";
import { useCallsStore } from "../store/useCallsStore";
import { usePerCallsStore } from "../store/usePerCallsStore";

import LoginComponent from "../components/modals/login.vue";
import ZimplerComponent from "../components/modals/zimpler.vue";
import RegisterComponent from "../components/modals/register.vue";
import DynamicComponent from "../components/dynamic/dynamic.vue";
import PaymentsComponent from "../components/modals/payments.vue";
// import TransactionStatusComponent from "../components/modals/transactionStatus.vue";
import SearchComponent from "../components/modals/search.vue";
import ResetPasswordComponent from "../components/modals/resetPassword.vue";
import EmailVerificationComponent from "../components/modals/emailVerification.vue";
import GamesLobbiesComponent from "../components/games/lobbies.vue";
import GameLobbyComponent from "../components/games/lobby.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "Home",
            component: () => {
                if (useConfigStore().config.home === "two") {
                    return import("../components/home/homeTwo.vue");
                } else if (useConfigStore().config.home === "splash") {
                    return import("../components/home/splash.vue");
                }
                return import("../components/home/home.vue");
            },
            meta: {
                showBanner: true,
            },
            children: [
                {
                    path: "/login",
                    name: "Login",
                    component: LoginComponent,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/play",
                    name: "Play",
                    component: ZimplerComponent,
                    meta: {
                        showModal: true,
                    },
                },
                {
                    path: "/register",
                    name: "Register",
                    component: RegisterComponent,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                        hideHeaderModal: true,
                    },
                },
                {
                    path: "/reward",
                    name: "Wheel Reward",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "reward",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account",
                    name: "Account",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "account",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account/profile",
                    name: "My Profile",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "myProfile",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/account/documents",
                    name: "My Documents",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "documents",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/bonuses",
                    name: "My Bonuses",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "myBonuses",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/history",
                    name: "My History",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "myHistory",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/responsible",
                    name: "Responsible",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "responsible",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                    },
                },
                {
                    path: "/account/change-password",
                    name: "Change Password",
                    component: DynamicComponent,
                    props: {
                        folder: "accountPages",
                        componentPath: "changePassword",
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                        showAccountBackButton: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/limits",
                    name: "Set Limits",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "limits",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        loggedIn: true,
                    },
                },
                {
                    path: "/payments/:type",
                    name: "Payments",
                    component: PaymentsComponent,
                    props: true,
                    meta: {
                        showModal: true,
                        loggedIn: true,
                    },
                },
                // {
                //     path: "/transaction-status/:id/:status/",
                //     name: "Payment Status",
                //     component: TransactionStatusComponent,
                //     props: true,
                //     meta: {
                //         showModal: true,
                //         loggedIn: true,
                //     },
                // },
                {
                    path: "/game",
                    name: "Game",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "game",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        fullScreenModal: true,
                    },
                },
                {
                    path: "/search/:searchBy?",
                    name: "Search",
                    component: SearchComponent,
                    props: true,
                    meta: {
                        showModal: true,
                        fullScreenModal: true,
                    },
                },
                {
                    path: "/forgot-password",
                    name: "Forgot Password",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "forgotPassword",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        loggedOut: true,
                        narrowContentModal: true,
                    },
                },
                {
                    path: "/reset-password/:hash",
                    name: "Reset Password",
                    component: ResetPasswordComponent,
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/email-verification/:code",
                    name: "Email Verification",
                    component: EmailVerificationComponent,
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/activate/:code",
                    name: "Manual Email Verification",
                    component: EmailVerificationComponent,
                    props: true,
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/account-verification",
                    name: "Account Verification",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "accountVerification",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        loggedOut: true,
                    },
                },
                {
                    path: "/languages",
                    name: "Languages",
                    component: DynamicComponent,
                    props: {
                        folder: "modals",
                        componentPath: "languages",
                        outOfDynamicFolder: true,
                    },
                    meta: {
                        showModal: true,
                        narrowContentModal: true,
                    },
                },
            ],
        },
        {
            path: "/lobbies/:lobbyType",
            name: "Lobbies",
            component: GamesLobbiesComponent,
            props: true,
        },
        {
            path: "/lobby/:lobby_id",
            name: "Lobby",
            component: GameLobbyComponent,
            props: true,
        },
        {
            path: "/promotions",
            name: "Promotions",
            component: DynamicComponent,
            props: {
                folder: "promotions",
                componentPath: "promotions",
                outOfDynamicFolder: true,
            },
        },
        {
            path: "/shop",
            name: "Shop",
            component: DynamicComponent,
            props: {
                folder: "shop",
                componentPath: "shop",
                outOfDynamicFolder: true,
            },
        },
        {
            path: "/gamification",
            name: "Gamification",
            component: DynamicComponent,
            props: {
                componentPath: "../gamification/gamification.vue",
            },
        },
        {
            path: "/promotions/wheel",
            name: "Wheel",
            component: DynamicComponent,
            props: {
                folder: "gamification",
                componentPath: "wheelInfo",
                outOfDynamicFolder: true,
            },
            meta: {
                showBanner: true,
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
        },
    ],
});

router.beforeEach((to, from, next) => {
    const myStore = useMyStore();

    // Blocking Navigation
    if (myStore.blockNavigation) {
        return next(false);
    }

    if (to.params && to.params.scrollId) {
        // We are not scrolling to top instead we are scrolling to the element
    } else {
        $("html, body").animate({ scrollTop: 0 });
    }

    if (from.meta.showModal !== true) {
        setSideMenuVisibility(false);
    }
    if (to.meta.showModal !== true && from.meta.showModal === true) {
        // To route is not a modal, Since modal is currently opened we need to close it
        setModalVisibility(false);
    }

    if (myStore.loggedIn) {
        // User Logged In
        if (to.meta.loggedOut === true) {
            // Route is LoggedOut only
            myStore.setAlert({ text: "WARNING_NAVIGATION", classes: "warning" });
            return next(false);
        }
    } else {
        // User Logged Out
        if (to.meta.loggedIn === true) {
            // Route is LoggedIn only
            myStore.setAlert({ text: "WARNING_NAVIGATION", classes: "warning" });
            return next("/login");
        }
    }

    // pages and promotion routes are created dynamically, and it was resulting in a page not found warning when accessed directly
    // The warning was removed by matching the yet unexisted route with a catch all route named NotFound.
    // And when this happen, we set a slight delay to give some time for the dynamic routes to be creted, and replace the route
    if (to.name === "NotFound" && (to.path.indexOf("/pages/") !== -1 || to.path.indexOf("/promotions/") !== -1 || to.path.indexOf("/register/") !== -1) && myStore.routeRetries < 9) {
        const fullPath = to.fullPath;
        setTimeout(function () {
            myStore.setRouteRetries(myStore.routeRetries + 1);
            router.replace(fullPath);
        }, 250);
    } else if (to.name === "NotFound") {
        return next({ path: "/" });
    } else {
        return next();
    }
});

router.afterEach((to, from) => {
    const config = useConfigStore().config;
    const unFormattedTotalBalance = useCallsStore().unFormattedTotalBalance;

    // Check path and name and set the browser title to the according page
    if (to.path && to.name) {
        document.title = config.originalName ? `${config.originalName} - ${to.name}` : to.name;

        // Set the canonical link for SEO purposes
        const canonicalLink = document.getElementById("canonicalLink");
        if (canonicalLink !== null) {
            canonicalLink.href = `${config.originalDomain}${to.path}`;
        }
    }

    // CreateCookies for user activity purposes
    // lnt = lastNavigationTime
    createCookie("lnt", new Date(), 1);
    // lntb = lastNavigationTotalBalance
    createCookie("lntb", unFormattedTotalBalance, 1);

    //WheelOfFortune
    if (usePerCallsStore().userActiveBoxesArray.length > 0) {
        createCookie("wofFullScreenNotification", "true", 2);
    }
});

export default router;
