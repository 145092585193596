<template>
    <div class="zimpler-component" :class="{ 'mt-3': !external }">
        <div v-if="myStore.loggedIn && !callsStore.userObject.email && !callsStore.userObject.phone && !zimplerPlayMode && !external" class="zimpler-container">
            <p v-html="getDictionary('ZIMPLER_LAST_STEP')"></p>
            <form-field type="text" :v$="v$.email" fieldName="EMAIL" />
            <div class="my-row">
                <div class="col-5"><select-field-long :v$="v$.dialCode" fieldName="DIAL_CODE" :location="callsStore.locationObject" :options="perCallsStore.countriesArray" margin="yes" /></div>
                <div class="col-7"><form-field type="number" :v$="v$.phone" fieldName="PHONE" /></div>
            </div>
            <button type="button" class="btn skin-btn no-border wide mt-2" @click="updateUser()" :disabled="status === 'PENDING'">
                <font-awesome-icon v-if="status === 'PENDING'" icon="fa-solid fa-spinner" spin pulse />
                <span v-else v-html="getDictionary('UPDATE_PROFILE')"></span>
            </button>
        </div>
        <div v-else-if="redirectUri" class="zimpler-container">
            <iframe id="zimpler-iframe" width="100%" height="100%" allow="xr-spatial-tracking" scrolling="no" :src="redirectUri"></iframe>
        </div>
        <div v-else>
            <div class="banner-register" :class="configStore.config.skin" v-if="!external && selectedWelcomeOffer && (callsStore.countryCode !== 'se' || configStore.config.allowBonusesInSE)" :style="{ backgroundImage: `url(${promoOrBannerImageUrl(selectedWelcomeOffer)}` }">
                <span v-html="getDictionary(null, selectedWelcomeOffer.offer)"></span>
            </div>

            <div class="btn-group wide mt-2" v-if="!external && !zimplerPlayMode">
                <button type="button" class="btn skin-btn" :class="[amount && amount.toString() === '50' ? 'no-border' : 'second']" @click="amount = '50'">50 {{ callsStore.userCurrency }}</button>
                <button type="button" class="btn skin-btn" :class="[amount && amount.toString() === '100' ? 'no-border' : 'second']" @click="amount = '100'">100 {{ callsStore.userCurrency }}</button>
                <button type="button" class="btn skin-btn" :class="[amount && amount.toString() === '200' ? 'no-border' : 'second']" @click="amount = '200'">200 {{ callsStore.userCurrency }}</button>
            </div>

            <div class="col text-start" v-if="!zimplerPlayMode">
                <form-field type="number" :v$="v$.amount" fieldName="AMOUNT" />
                <label class="label-outside error text-start" v-if="v$.amount.$invalid">
                    <span v-html="getDictionary('MINIMUM_AMOUNT')"></span>
                    {{ minValue }}{{ callsStore.userCurrency }} <span v-html="getDictionary('AND')"></span>&nbsp;
                    <span v-html="getDictionary('MAXIMUM_AMOUNT')"></span>
                    {{ maxValue }}{{ callsStore.userCurrency }}
                </label>
            </div>

            <div class="my-2">
                <font-awesome-icon v-if="status === 'PENDING'" icon="fa-solid fa-spinner" spin pulse />
                <button v-else type="button" class="btn skin-btn wide" @click="depositAndPlay('depositAndPlay')" v-html="getDictionary('DEPOSIT_PLAY')"></button>
            </div>

            <strong class="secure" v-if="!external"><font-awesome-icon icon="fa-solid fa-shield-check" /> <span v-html="getDictionary('SECURE_100')"></span></strong>
        </div>
    </div>
</template>

<script>
import { authorizeProvider, getBrandCountries, updateUser, getUser } from "../../services/tma/axiosTmaRequests";
import { getCookie, promoOrBannerImageUrl, setModalVisibility } from "../../helpers/helpers";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue, maxValue, email, minLength, maxLength } from "@vuelidate/validators";
import FormField from "../dynamic/others/formField.vue";
import SelectFieldLong from "../dynamic/others/selectFieldLong.vue";
// import { pushGtmEvent } from "../../helpers/gtm_helpers";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "zimpler-component",
    data: function () {
        return {
            amount: 50,
            minValue: 20,
            maxValue: 50000,
            redirectUri: null,
            status: null,
            email: null,
            phone: null,
            dialCode: null,
        };
    },
    validations() {
        return {
            amount: {
                required,
                minValue: minValue(this.minValue),
                maxValue: maxValue(this.maxValue),
            },
            email: {
                required,
                email,
            },
            dialCode: { required },
            phone: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(20),
            },
        };
    },
    components: {
        FormField,
        SelectFieldLong,
    },
    props: {
        external: Boolean,
    },
    computed: {
        selectedWelcomeOffer() {
            if (this.perCallsStore.promotionsObject && this.perCallsStore.promotionsObject.selectedWelcomeOffer) {
                return this.perCallsStore.promotionsObject.selectedWelcomeOffer;
            }
        },
        zimplerPlayMode() {
            return this.myStore.zimplerPlayMode;
        },
    },
    watch: {
        zimplerPlayMode: function () {
            if (this.zimplerPlayMode && this.external) {
                this.depositAndPlay("play");
                setTimeout(
                    function () {
                        this.myStore.setZimplerPlayMode(false);
                    }.bind(this),
                    3000,
                );
            }
        },
    },
    created() {
        // if (getCookie("affiliateId") !== "") {
        //     pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Login_Opened" });
        // }
        getBrandCountries(this.perCallsStore, this.configStore.config.skin, this.myStore, this.callsStore, this.$router);
        window.addEventListener(
            "keypress",
            function (event) {
                if (event.key === "Enter" && this.$route.name === "Play") {
                    event.preventDefault();
                    this.depositAndPlay();
                }
            }.bind(this),
        );
    },
    methods: {
        promoOrBannerImageUrl(item) {
            return promoOrBannerImageUrl(item, true);
        },
        async depositAndPlay(action) {
            this.status = "PENDING";

            let skin = this.configStore.config.skin;
            if (skin === "zu") {
                skin = "lj";
            } else if (skin === "nw") {
                skin = "ra";
            }

            let query = `
                provider: ZIMPLER,
                brandId: "${skin}",
                affiliateId: "${getCookie("affiliateId")}",
                affiliateTag:"${getCookie("affiliateToken")}",
                currencyCode: "EUR",
                countryCode: "FI",
                extraIdentification: true
                landingUri: "${document.location.origin}/",
                redirectUri: "${document.location.origin}/",
                selectedBonusCode: ""
            `;

            if (action === "depositAndPlay") {
                let isFormCorrect = await this.v$.amount.$validate();
                if (!isFormCorrect) {
                    this.status = "";
                    return;
                }

                query = `${query}, amount: "${parseFloat(this.amount)}"`;
            }

            console.log(`{${query}}`);

            authorizeProvider(`{${query}}`, skin, this.myStore, this.perCallsStore, this.callsStore, this.$router).then((response) => {
                if (response.success && response.redirectUri) {
                    console.log(response);
                    this.status = "";
                    this.redirectUri = response.redirectUri;
                    if (this.external) {
                        $(".black-backdrop").addClass("visible");
                        $(".container-position").addClass("high-z-index");
                    }
                }
            });
        },
        async updateUser() {
            this.status = "PENDING";

            let validateForm = await this.v$.$validate();
            let isFormCorrect = (await this.v$.email.$validate()) && (await this.v$.dialCode.$validate()) && (await this.v$.phone.$validate());
            if (!isFormCorrect) {
                this.status = "";
                return;
            }

            const queryInput = `{
                userId: "${getCookie("userId")}",
                email: "${this.email}",
                phone: "+${this.dialCode.dialCode} ${this.phone}",
                receiveEmail: ${true},
            }`;

            updateUser(queryInput, this.myStore, getCookie("accessToken"), this.callsStore, this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
                if (response.success) {
                    this.myStore.setBlockNavigation(false);
                    setModalVisibility(false, this.$router);
                    this.myStore.setAlert({ text: "SUCCESS_PROFILE", classes: "success" });
                    getUser(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.configStore.config.skin, this.perCallsStore);
                    this.status = "SUCCESS";
                } else {
                    this.status = "FAILED";
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.zimpler-component {
    .zimpler-container {
        height: 700px;
        width: 100%;
        iframe {
            overflow: hidden;
        }
    }
    .banner-register {
        background-color: var(--darken-site-background);
        position: relative;
        margin: 10px auto;
        background-position: center center;
        height: 180px;
        max-width: 550px;
        background-size: cover;

        span {
            background-color: var(--game-hover);
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            color: var(--game-hover-text);
            width: 100%;
            font-size: 16px;
            padding: 5px;
        }

        &.ra {
            background-position: center bottom;
        }

        @media only screen and (min-width: 500px) {
            span {
                font-size: 20px;
            }
        }

        @media only screen and (min-height: 750px) {
            height: 240px;
        }
    }
}
</style>
